<template>
  <div class="login-component">
    <v-container id="login" class="fill-height justify-center" tag="section">
      <v-row class="center mt-6">
        <v-col lg="11" xl="7" class="padding">
          <v-card class="elevation-4">
            <div class="myrow">
              <v-col class="info d-none d-md-flex align-center justify-center">
                <div class="d-none d-sm-block">
                  <div class="d-flex align-center pa-10">
                    <div>
                      <h2 class="display-1 white--text font-weight-medium">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor
                      </h2>
                      <h6
                        class="
                          subtitle-1
                          mt-4
                          white--text
                          op-5
                          font-weight-regular
                        "
                      >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </h6>
                      <v-btn
                        class="mt-4 text-capitalize"
                        x-large
                        outlined
                        color="white"
                        >Learn More</v-btn
                      >
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col lg="5">
                <div class="pa-2 pa-sm-6">
                  <img class="img" src="../../assets/MedDrive-logo.jpeg" alt />
                  <h2
                    class="
                      font-weight-bold
                      mt-4
                      blue-grey--text
                      text--darken-2
                      title
                    "
                  >
                    Sign in
                  </h2>
                  <h6 class="subtitle-1" style="font-weight: bold">
                    Don't have an account?
                    <router-link :to="{ name: 'Register' }" class="uk-text-bold"
                      >Sign Up</router-link
                    >
                  </h6>

                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    action="/dashboards/view"
                  >
                    <v-text-field
                      v-model="email"
                      hide-details="auto"
                      :rules="emailRules"
                      label="E-mail"
                      class="mt-4"
                      required
                      outlined
                    ></v-text-field>
                    <v-text-field
                      v-model="password"
                      :counter="10"
                      hide-details="auto"
                      :rules="passwordRules"
                      label="Password"
                      required
                      outlined
                      class="mt-4"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show1 = !show1"
                      :type="show1 ? 'text' : 'password'"
                    ></v-text-field>
                    <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                      <v-checkbox
                        v-model="agreeOnPrivacy"
                        hide-details="auto"
                        :rules="[(v) => !!v || 'You must agree to continue!']"
                        label="I agree to the terms and privacy"
                        required
                      ></v-checkbox>
                    </div>
                    <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                      <v-checkbox
                        v-model="checkbox"
                        label="Keep me signed in"
                      ></v-checkbox>
                    </div>
                    <v-btn
                      :disabled="!valid"
                      color="info"
                      block
                      class="mr-4"
                      submit
                      @click="submit"
                      >Sign In</v-btn
                    >
                    <div class="ml-auto uk-width-1-1 mt-4 forgot-password-btn">
                      <router-link to="/forgot-password" class="link"
                        >Forgot pwd?</router-link
                      >
                    </div>
                  </v-form>
                </div>
              </v-col>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar">
        {{ notification }}
        <template v-slot:action="{ attrs }">
          <v-btn color="#fc4b6c" text v-bind="attrs" @click="snackbar = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BoxedLogin",

  data: () => ({
    valid: true,
    password: "",
    show1: false,
    agreeOnPrivacy: false,
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        (v && v.length <= 10) || "Password must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    checkbox: false,
    notification: "",
    snackbar: false,
  }),
  computed: {
    ...mapGetters([
      "strapi",
      "isAdmin",
      "isClientAdmin",
      "isClientUser",
      "isDriver",
    ]),
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        // this.$router.push({ path: "/dashboards/view" });
        this.$store
          .dispatch("auth/login", {
            username: this.email,
            password: this.password,
            rememberme: this.checkbox,
          })
          .then((response) => {
            this.notification = "Successfull";
            this.snackbar = true;

            // console.log("window.firebase", window.firebase);
            const messaging = window.firebase.messaging();
            // console.log("messaging", messaging);
            messaging.getToken().then(async (token) => {
              // console.log("token", token);
              var updatedItem = await this.strapi.updateEntry(
                "users",
                parseInt(response.user.id),
                {
                  pushTokenWeb: token,
                }
              );
            });

            messaging.onTokenRefresh(function () {
              messaging.getToken().then(async function (refreshedToken) {
                // console.log("refreshedToken", refreshedToken);
                var updatedItem = await this.strapi.updateEntry(
                  "users",
                  parseInt(response.user.id),
                  {
                    pushTokenWeb: token,
                  }
                );
              });
            });

            if (this.isClientAdmin || this.isClientUser) {
              this.$router.push({ name: "CalendarPage" });
            } else if (this.isDriver || this.isClientAdmin) {
              this.$router.push({ name: "OrdersPage" });
            } else {
              this.$router.push({ name: "Dashboard" });
            }
          })
          .catch((err) => {
            console.error("err", err);
            this.notification = "Error";
            this.snackbar = true;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.login-component {
  padding: 65px 0 100px 0;

  @media screen and (max-width: 940px) {
    padding: 30px 0 65px 0;
  }
}

.margin-b {
  margin-bottom: 70px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss" scoped>
.forgot-password-btn {
  text-align: center;
}

.img {
  width: 100%;
  max-width: 140px;
  margin: 0px auto;

  @media screen and (max-height: 730px) {
    display: none;
  }

  @media screen and (max-width: 370px) {
    display: none;
  }
}

#login {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.myrow {
  display: flex;
}

.padding {
  padding: 0 55px;

  @media screen and (max-width: 485px) {
    padding: 0 15px;
  }
}

.title {
  @media screen and (max-height: 730px) {
    margin-top: 0 !important;
  }

  @media screen and (max-width: 370px) {
    margin-top: 0 !important;
  }
}
</style>
